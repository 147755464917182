// src/hooks/useErrorHandler.js
import { useCallback, useContext, useRef } from "react";
import { AppContext } from "../context/AppContext";
import authApi from "../api/authApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const useErrorHandler = () => {
  const { showErrorToast, setShowSubscriptionModal } = useContext(AppContext);
  const navigate = useNavigate();
  const lastErrorTimestamp = useRef(0);
  const debounceTime = 5000; // 5 seconds

  const handleError = useCallback(
    (error) => {
      console.log(error);
      const currentTime = Date.now();

      if (error.response && error.response.data) {
        const statusCode = error.response.status;
        const message =
          error.response.data?.errors?.[0] ||
          error.response.data?.message ||
          error.response.data;

        if (statusCode === 401) {
          // Check if it's a login attempt
          if (error.config.url.includes("/login")) {
            // This is a login error
            showErrorToast(message);
          } else {
            // This is a session expiration
            // Check if enough time has passed since the last error
            if (currentTime - lastErrorTimestamp.current > debounceTime) {
              authApi.logout();
              toast.error("Your session has expired. Please log in again.");
              window.dispatchEvent(new CustomEvent("authenticationFailed"));
              setTimeout(() => {
                navigate("/login", { replace: true });
              }, 100);
              lastErrorTimestamp.current = currentTime;
            }
          }
        } else if (statusCode === 402) {
          setShowSubscriptionModal(true);
        } else if (message) {
          showErrorToast(message);
        } else if (statusCode) {
          showErrorToast(`Error: ${statusCode}`);
        }
      } else if (error.message) {
        // showErrorToast(error.message);
      }
    },
    [showErrorToast, setShowSubscriptionModal, navigate]
  );

  return handleError;
};
