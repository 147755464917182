import axios from 'axios';
import config from '../config/env';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../utils/translations';

const API_URL = config.API_URL;

// Create an axios instance with default configuration
const api = axios.create({
  baseURL: API_URL,
});

// Global state for subscription modal and limits refresh function
let showSubscriptionModal = null;
let refreshLimitsGlobal = null;

// Function to set the global showSubscriptionModal function
export const setGlobalShowSubscriptionModal = (modalFunction) => {
  showSubscriptionModal = modalFunction;
};

// Function to set the global refreshLimits function
export const setGlobalRefreshLimits = (refreshFunction) => {
  refreshLimitsGlobal = refreshFunction;
};

// Add a request interceptor to append the authorization header to all requests
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Add a response interceptor to handle errors globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorMessage = getErrorMessage(error.response?.data?.message) || 'An error occurred';
    if (error.response && error.response.status === 402) {
      if (showSubscriptionModal) {
        showSubscriptionModal();
      }
      // Don't show the error toast for 402 errors
    } else {
      // Instead of showing the toast here, we'll set a flag on the error object
      error.hasBeenHandled = true;
      error.handledMessage = errorMessage;
    }
    return Promise.reject(error);
  }
);

const generalApi = {
  // Keyword related API calls
  keyword: {
    createKeyword: async (keyword) => {
      try {
        const response = await api.post("/project/keywords/create", keyword);
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getKeywordsByProjectId: async (projectId) => {
      try {
        const response = await api.get(`/project/${projectId}/keywords`);
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getKeyword: async (keywordId) => {
      try {
        const response = await api.get(`/keywords/${keywordId}`);
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getKeywordForParasite: async (keywordId) => {
      try {
        const response = await api.get(`/parasite/${keywordId}`);
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    updateKeyword: async (keywordId, keywordData) => {
      try {
        const response = await api.put(`/keyword/${keywordId}`, keywordData);
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    deleteKeyword: async (keywordId) => {
      try {
        const response = await api.delete(`/keyword/${keywordId}`);
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    deleteKeywords: async (keywordIds) => {
      try {
        const response = await api.post("/project/keywords/delete", {
          ids: keywordIds,
        });
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getKeywordByProjectIdAndKeyword: async (projectId, keyword) => {
      try {
        const response = await api.get(
          `/keyword/project/${projectId}/keyword/${encodeURIComponent(keyword)}`
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    deleteKeywordsByProjectId: async (projectId) => {
      try {
        const response = await api.delete(`/keyword/project/${projectId}`);
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    updateKeywordRefreshDate: async (keywordId) => {
      try {
        const response = await api.put(`/project/keywords/refresh/${keywordId}`);
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  },

  // Category related API calls
  category: {
    // getCategories: async () => {
    //   try {
    //     const response = await api.get("/general/categories");
    //     return response.data.results;
    //   } catch (error) {
    //     throw error;
    //   }
    // },

    getTrendingCategories: async () => {
      try {
        const response = await api.get("/general/trending-categories");
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getParasiteCategory: async (category) => {
      try {
        const response = await api.get(
          `/parasite/get-parasites-for-category/${category}`
        );
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  },

  // Project related API calls
  project: {
    createProject: async (projectData) => {
      try {
        const response = await api.post("/project", projectData);
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getProjects: async () => {
      try {
        const response = await api.get("/project");
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getProjectsLists: async () => {
      try {
        const response = await api.get("/project/list");
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    updateProject: async (projectData) => {
      try {
        const response = await api.put(
          `/project/${projectData._id}`,
          projectData
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    deleteProject: async (projectId) => {
      try {
        const response = await api.delete(`/project/${projectId}`);
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  },

  city: {
    getCitiesByCountry: async (countryCode, searchEngine, search = "") => {
      try {
        const response = await api.get(
          `/cities/${countryCode}?searchEngine=${searchEngine}&search=${search}`
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching cities:", error);
        throw error;
      }
    },
  },

  // Keyword analysis
  getKeywordAnalysis: async (
    keyword,
    country,
    city,
    language,
    searchEngine,
    typeParams,
    keywordId = null
  ) => {
    try {
      const data = {
        keyword,
        country,
        city,
        language,
        searchEngine,
        ...typeParams
      };
      if (keywordId) {
        data.keywordId = keywordId;
      }
      const response = await api.post("/parasite/keyword-analysis", data);
      if (refreshLimitsGlobal) {
        await refreshLimitsGlobal();
      }
      return response.data;
    } catch (error) {
      console.error("Error in keyword analysis:", error);
      throw error;
    }
  },

  bulkKeywordAnalysis: async (keywords, country, city, language, searchEngine, projectId, typeParams) => {
    try {
      const response = await api.post("/parasite/bulk-keyword-analysis", {
        keywords, country, city, language, searchEngine, projectId, ...typeParams
      });
      if (refreshLimitsGlobal) {
        await refreshLimitsGlobal();
      }
      return response.data;
    } catch (error) {
      console.error("Error in bulk keyword analysis:", error);
      throw error;
    }
  },

  aiOverview: {
    getAiOverview: async (keywordId) => {
      try {
        const response = await api.post("/ai-overview", { keywordId });
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  },

  /** Notification API endPoints */
  notifications: {
    getNotifications: async (page = 1, limit = 30) => {
      try {
        const response = await api.get(
          `/notifications?page=${page}&limit=${limit}`
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching notifications:", error);
        throw error;
      }
    },
    markNotificationAsSeen: async (notificationId) => {
      try {
        const response = await api.put(`/notifications/${notificationId}/seen`);
        return response.data;
      } catch (error) {
        console.error("Error marking notification as seen:", error);
        throw error;
      }
    },
    markGenaralNotificationAsSeen: async (notificationId) => {
      try {
        const response = await api.put(
          `/generalNotification/${notificationId}/seen`
        );
        return response.data;
      } catch (error) {
        console.error("Error marking notification as seen:", error);
        throw error;
      }
    },
    markAllJobNotificationsAsRead: async () => {
      try {
        const response = await api.post("/notifications/mark-all-read");
        return response.data;
      } catch (error) {
        console.error("Error marking all notifications as read:", error);
        throw error;
      }
    },
    markAllMarketingNotificationsAsRead: async () => {
      try {
        const response = await api.post("/generalNotification/mark-all-seen");
        return response.data;
      } catch (error) {
        console.error("Error marking all notifications as read:", error);
        throw error;
      }
    },
    toggleNotificationSeenStatus: async (notificationId, newSeenStatus) => {
      try {
        const response = await api.put(`/notifications/${notificationId}/toggle-seen`, { seenStatus: newSeenStatus });
        return response.data;
      } catch (error) {
        console.error("Error toggling notification seen status:", error);
        throw error;
      }
    },
    toggleGeneralNotificationSeenStatus: async (notificationId, newSeenStatus) => {
      try {
        const response = await api.put(`/generalNotification/${notificationId}/toggle-seen`, { seenStatus: newSeenStatus });
        return response.data;
      } catch (error) {
        console.error("Error toggling general notification seen status:", error);
        throw error;
      }
    },
  },
}

export default generalApi;