// TypeSelector.js
import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, Tooltip } from '@mui/material';

const types = [
  { name: "General", value: "general" },
  { name: "Local", value: "local" },
  { name: "ORM (Local)", value: "orm_local" },
  { name: "ORM (Individual)", value: "orm_person" },
  { name: "ORM (Company)", value: "orm_company" },
  { name: "Video", value: "video", comingSoon: true },
  { name: "SGE", value: "sge", comingSoon: true },
];

function TypeSelector({ labelId, id, value, onChange, required }) {
    const handleChange = (event) => {
        const selectedValue = event.target.value;
        const newTypeParams = {
          isLocal: false,
          ormLocal: false,
          ormCompany: false,
          ormPerson: false
        };
      
        switch (selectedValue) {
          case 'local':
            newTypeParams.isLocal = true;
            break;
          case 'orm_local':
            newTypeParams.ormLocal = true;
            break;
          case 'orm_person':
            newTypeParams.ormPerson = true;
            break;
          case 'orm_company':
            newTypeParams.ormCompany = true;
            break;
          // 'general' case is handled by default initialization
        }
      
        onChange(selectedValue, newTypeParams);
        console.log("TypeSelector - selectedValue:", selectedValue);
        console.log("TypeSelector - newTypeParams:", newTypeParams);
        
        localStorage.setItem("lastType", selectedValue);
      };

  return (
    <FormControl fullWidth required={required}>
      <InputLabel id={labelId}>Mode</InputLabel>
      <Select
        labelId={labelId}
        id={id}
        value={value}
        onChange={handleChange}
        label="Mode"
        sx={{
          backgroundColor: '#2C2C2C',
          color: '#fff',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#333',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#444',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#666',
          },
          '& .MuiSvgIcon-root': {
            color: '#fff',
          },
        }}
      >
        {types.map((type) => (
          <MenuItem
            key={type.value}
            value={type.value}
            disabled={type.comingSoon}
            sx={{
              opacity: type.comingSoon ? 0.5 : 1,
              '&.Mui-disabled': {
                color: 'grey',
              },
            }}
          >
            <Tooltip title={type.comingSoon ? "Coming Soon" : ""} placement="right">
              <span>{type.name}{type.comingSoon && " (Coming Soon)"}</span>
            </Tooltip>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default TypeSelector;