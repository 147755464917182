// WhatToPromote.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Link,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TableContainer,
} from "@mui/material";

const affiliatePrograms = [
  {
    name: "FanFuel",
    category: "Supplements, Health & Wellness",
    url: "https://partners.fanfuel.co/affiliate/signup/?p=2GQ7636C1"
  },
  {
    name: "Health Nutrition",
    category: "Supplements, Health & Wellness",
    url: "https://affiliates.healthnutrition.com/affiliate/signup/?p=7K5PFCR1"
  },
  {
    name: "CBDaff",
    category: "CBD & Cannabis, Delta 8",
    url: "https://system.cbdaffs.com/signup/3820"
  },
  {
    name: "Health Trader",
    category: "Supplements, Health & Wellness",
    url: "https://track.healthtrader.com/track.php?c=cmlkPTc5MDg0MSZhaWQ9NjQyOTI0ODUmc2lkPTk5OTk"
  },
  {
    name: "Crakrevenue",
    category: "Dating, Adult",
    url: "https://www.crakrevenue.com/?r=340716"
  },
  {
    name: "iWhale iRev",
    category: "iGaming, Gambling, Betting",
    url: "https://iwhale.irev.com/affiliates/auth/signup?hash=2c492b0a6ada8245c3694d3a2cc6af8c"
  }
];

const categories = [
  "All",
  "Supplements, Health & Wellness",
  "CBD & Cannabis, Delta 8",
  "Dating, Adult",
  "iGaming, Gambling, Betting"
];

function WhatToPromote() {
    const [selectedCategory, setSelectedCategory] = useState("All");
  
    const filteredPrograms = selectedCategory === "All"
      ? affiliatePrograms
      : affiliatePrograms.filter(program => program.category.includes(selectedCategory));
  
    return (
      <Box sx={{ bgcolor: "#121212", minHeight: "100%" }}>
        <Typography variant="h4" sx={{ color: "#fff", mb: 3 }}>
          Offers to Promote
        </Typography>
  
        <Card sx={{ 
          bgcolor: "#1E1E1E", 
          p: 3,
        }}>
          <FormControl sx={{ mb: 4, minWidth: 250 }}>
            <InputLabel id="category-select-label" sx={{ 
              color: "#fff", 
              fontSize: '1.2rem',
              '&.Mui-focused': {
                color: '#fff',
              },
            }}>
              Category
            </InputLabel>
            <Select
              labelId="category-select-label"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              sx={{ 
                color: "#fff", 
                fontSize: '1.2rem',
                '& .MuiOutlinedInput-notchedOutline': { 
                  borderColor: 'rgba(255, 255, 255, 0.23)' 
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.23)'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.23)'
                },
                '& .MuiSelect-icon': {
                  color: '#fff'
                }
              }}
            >
              {categories.map((category) => (
                <MenuItem key={category} value={category} sx={{ fontSize: '1.2rem' }}>{category}</MenuItem>
              ))}
            </Select>
          </FormControl>
  
          <Box sx={{ overflow: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ bgcolor: "#2C2C2C", color: "#fff", width: "35%", fontSize: '1.3rem', fontWeight: 'bold' }}>Name</TableCell>
                  <TableCell sx={{ bgcolor: "#2C2C2C", color: "#fff", width: "65%", fontSize: '1.3rem', fontWeight: 'bold' }}>Category</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredPrograms.map((program, index) => (
                  <TableRow key={index} sx={{ "&:nth-of-type(odd)": { bgcolor: "#252525" } }}>
                    <TableCell sx={{ color: "#fff", fontSize: '1.2rem' }}>
                      <Link href={program.url} target="_blank" rel="noopener noreferrer" sx={{ color: "#6DB9FF", '&:hover': { color: '#9333EA' } }}>
                        {program.name}
                      </Link>
                    </TableCell>
                    <TableCell sx={{ color: "#fff", fontSize: '1.2rem' }}>{program.category}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Card>
      </Box>
    );
  }
  
  export default WhatToPromote;